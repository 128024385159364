.err {
  color: red;
  margin-top: 6px;
}
.form {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 18px;
  }

  &__caption {
    margin-top: 6px;
    font-size: 14px;
    color: #6b6b6b;
  }

  &__item {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }

  form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 60px 20px;


    button {
      font-size: 20px;
      background-color: #35b957;
      border-color: #ffffff57;
      padding: 10px 8px;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: initial;
      }
    }

    input, textarea {
      font-size: 16px;
      font-weight: 600;
      padding: 7px 0;
      margin-top: 10px;
      border: none;
      border-radius: 0;
      border-bottom: solid 1px #00000030;
      &:focus {
        outline: none;
        border-bottom-color: black !important;
      }
    }

    label {
      font-size: 20px;
      // margin-bottom: 10px;
    }
  }
}