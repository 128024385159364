body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.err {
  color: red;
  margin-top: 6px; }

.form {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px; }
  @media (max-width: 600px) {
    .form {
      padding: 18px; } }
  .form__caption {
    margin-top: 6px;
    font-size: 14px;
    color: #6b6b6b; }
  .form__item {
    margin-bottom: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .form form {
    width: 100%;
    max-width: 600px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 20px 60px 20px; }
    .form form button {
      font-size: 20px;
      background-color: #35b957;
      border-color: #ffffff57;
      padding: 10px 8px;
      border-radius: 4px;
      color: white;
      cursor: pointer; }
      .form form button:disabled {
        opacity: 0.5;
        cursor: auto;
        cursor: initial; }
    .form form input, .form form textarea {
      font-size: 16px;
      font-weight: 600;
      padding: 7px 0;
      margin-top: 10px;
      border: none;
      border-radius: 0;
      border-bottom: solid 1px #00000030; }
      .form form input:focus, .form form textarea:focus {
        outline: none;
        border-bottom-color: black !important; }
    .form form label {
      font-size: 20px; }

